import React from "react"

export default class EmptyProfilesDumpster extends React.Component 
{
    constructor(props) 
    {
        super(props);     
       
        this.title = ''
        this.text =  ''
        this.image = ''
        this.call = '' 
        this.woo = ''
        this.show = false
        
        
        switch (this.props.code) 
        {
            case 'junk-removal':
                this.show = true
                this.title = 'Hometown Junk Removal'
                this.text =  "Whether you're moving, need an estate clean-out, or you have some other cleanup project planned, Hometown can help.  We make it simple for you to get estimates from top, local junk removal services.  Just request a free quote and Hometown will forward your request on to a few of the most reputable local trash haulers in your area."
                this.image = "/junk-empty.jpg"
                this.call =  "Request a Junk Removal Estimate Today!"
                this.woo = "https://hometown.wufoo.com/forms/m1cgmngo0peh3u7/def/field36=Hometown%20Junk%20Removal&field37=CA6phU99gMMTqQCT"   
                break;

            case 'dumpster':
                this.show = true
                this.title = 'Hometown Dumpster Rental'
                this.text =  "Hometown verifies credentials and collects trusted reviews on local dumpster rental services in Hometown's across America. We make it simple for you to get the best prices when renting a dumpster. Just request a free quote and Hometown will forward your request on to a few of the most reputable local trash haulers in your area."
                this.image = "/dumpster-empty.png"
                this.call =  "Rent a Dumpster Today!"
                this.woo = "https://hometown.wufoo.com/forms/?formname=m1efqji50u48ejk&field36=Hometown%20Dumpster%20Rental&field37=CA6phE99gHRu1QBM"   
                break;

            default: 
                this.show = false 
                break
        }    


        this.ul = () =>
        {
            switch(this.props.code)    
            {
                case 'junk-removal':
                    return(<ul>
                        <li>Free, no-obligation estimates</li>
                        <li>Local junk removal companies contact you</li>
                        <li>Conquer your clutter.  Be free of your debris!</li>
                    </ul>)
                    break;

                default:
                    return(<ul>
                        <li>Hometown makes it simple to rent a dumpster</li>
                        <li>Submit one request, get multiple quotes</li>
                        <li>Top local trash haulers respond directly to you</li>
                    </ul>)   
                    break;
            }           
        }
    }

    reviewForm = () =>
    {
        if (typeof window !== "undefined") window.open(this.woo,  null, 'height=600, width=680, toolbar=0, location=0, status=1, scrollbars=1, resizable=1'); 
        
        return false
    }

    render() 
    {        
        if(!this.show) return <></>
        
        return (
            <div className={"emptyprofiles "+this.site}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 blog">

                            <div className="post">
                                
                                { this.site === 'dumpster' &&
                                <div className="image">
                                    <img src={this.image}/>
                                    <a href="#" onClick={this.reviewForm} className="btn white">Get a Free Quote</a>
                                </div> }
                                
                                <div className="text">
                                    <h3>{this.title}</h3>
                                    <div className="text">
                                        <div className="one">                                        
                                            <p>{this.text}</p>
                                            <span>{this.call}</span>
                                        </div>
                                        {this.ul()} 
                                    </div>  
                                </div>

                            </div>

                        </div>
                    </div>
                </div>  
            </div>
        )
    }
}