import React from "react";

export default class PopularArticles extends React.Component  
{
    constructor(props)    
    {
        super(props);

        this.articles = this.props.articles;


    }
    
    render()
    {
        return (
            <div className="container popular-articles">    
                <h2>Popular Articles</h2>            
                <div className="articles">
                    <div className="row" style={{gap: '20px'}}>
                    {
                        this.articles.map((article, index) =>
                        {                                                 
                            return(
                                <div key={index} className="articleContainer">
                                    <a href={article.url} className="eachArticle">
                                        <div className="image">
                                            <img src={article.image.path} alt={article.image.alt} loading="lazy"/>
                                        </div>
                                        <div className="text"><span>{article.title}</span></div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    </div> 
                </div>
            </div>
        )
    }
};