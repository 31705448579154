import React from "react"
import SEO from './seo'
import HeaderDumpster from './header-dumpster'
import FooterDumpster from './footer-dumpster'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export default function LayoutDumpster({ seo, children, env, code, page, bgcolor, footerColor, hidden })
{         

    const bg_color = bgcolor !== undefined ? bgcolor : 'white'   
    const fooC = footerColor !== undefined ? footerColor : ''  
    const main_style =
    {
        background:bg_color
    }
    useScrollPosition(({ prevPos, currPos }) => 
    {
        const showForm = currPos.y < -80;

        const header = document.getElementsByTagName('header');

        header[0].className = hidden === true ? '' : ( showForm === true ? 'showform' : '' );
    })
    
    return (
        <div id="wrapper">            

            <SEO seo={seo} />
            
            <header>
                <HeaderDumpster env={env} code={code} page={page}/>                
            </header>

            <main style={main_style}>
                {children}
            </main>
        
            <footer className={fooC}>
                <FooterDumpster/>
            </footer> 
        </div>
    ) 
}