import React, { lazy, Suspense, useEffect, useState } from 'react';
//import OnDeffImplementation from './on-deff-implementation'

import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))
const QuoteRequestModalLibrary = (props) => {
    const proId = props.proid;
    const startVisible = props.startVisible ? true : false;
    const [proName, setProName] = useState(props.pro_name)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(startVisible)
    const changeShowModal = () => {
        setShowModal(!showModal)
        setProName(props.pro_name)
    }

    let initialValuePoolLimit = 0;
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search)
        if (params.get("test") == "true") {
            initialValuePoolLimit = 1;
        }
    }
    const [limitPoolSize, setPoolTest] = useState(initialValuePoolLimit)

    const [modalStyles, setModalStyles] = useState(
        {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: props.ondeffCode != 'reviewForm' ? '#e8e8e8' : '#FFFFFF',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px 20px',
                minHeight: 'auto',
                //boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                width: '600px',
            }
        })

    return (
        <>
            {props.showButton && <button className={`get-a-quote ${props.class}`} onClick={changeShowModal}>{props.ondeffCode != 'reviewForm' ? ( props.mobileQuoteLabel ? props.mobileQuoteLabel : 'Get a Quote' ) : 'Write a Review'}</button>}
            {props.showButtonForProfile && <div className="modal_box">
                <button style={{ cursor: 'pointer' }} className={props.ondeffCode != 'reviewForm' ? "profile-button-form" : "profile-button-review-form"} onClick={changeShowModal}>{props.ondeffCode != 'reviewForm' ? 'Get a Quote' : 'Write a Review'}</button>
            </div>}
            <Modal
                id={1}
                isOpen={showModal}
                style={modalStyles}
                contentLabel={props.ondeffCode != 'reviewForm' ? 'Get a Quote!' : 'Write a Review'}
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={props.ondeffCode != 'reviewForm' ? "modal-header" : "modal-header review-quote-step-modal"} >
                    {proName && <h2 style={{color: '#263238', fontFamily: 'avenir-heavy', fontSize: '1.6rem', lineHeight: '2rem'}}>{proName}</h2>}
                    <button type="button" className="close closermodal" onClick={changeShowModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="quote-steps-modal-library">
                <Suspense fallback={<h1>Loading…</h1>}>
                    <OnDeffImplementation
                        initialData={props.ondeffCode != 'reviewForm' ? [{ key: 'pro_id', value: proId },{ key: 'limit_poolsize', value: limitPoolSize }] : [{ key: 'pro_id', value: proId },{ key: 'limit_poolsize', value: limitPoolSize }, { key: 'pro_name', value: props.pro_name }]}
                        formCode={props.ondeffCode}
                        loadingFunction={setLoading}
                        setProName={setProName}>
                    </OnDeffImplementation>
                    </Suspense>
                </div>
                <div id="temp"></div>
            </Modal></>
    )
}

export default QuoteRequestModalLibrary;