import React from "react";

export default class PopularServices extends React.Component  
{
    constructor(props)    
    {
        super(props);

        const code = this.props.code;
        const place = this.props.place.state !== undefined ? this.props.place.state.name : '';        
        this.place = place;
        this.services = this.props.services.length > 0 ? this.props.services : [];    
       
        let url_place_name;

        switch(place)
        {
            case 'District of Columbia':
                url_place_name = place.replace(/ /g,'-').toLowerCase();
                break;

            default:
                url_place_name = place.replace(/ /g,'-');
                break;
        }

        if(this.props.env.site === 'dumspter')
        {
            this.services.push({
                'place':place,
                'code_name': 'Dumpster Rental',
                'url': url_place_name +"/index.html",
                'code': 'static'
            });

            this.services.push({
                'place':place,
                'code_name': 'Junk Removal',
                'url': url_place_name + "/junk-removal/index.html",
                'code': 'static'
            });
        }

        this.ulclass = code !== undefined ? code : '';
        
    }
    
    render()
    {
        return (
            <div className={"block popular-services "+this.props.env.site}>
                <h2 className="text-center">Popular Services in {this.place}</h2>
                <article className="provider-card card">
                    <ul className={"items-list "+this.ulclass}>
                        {
                            this.services.map((service, index) =>
                            {                                
                                return(
                                    <li key={index} className={"item"}>
                                        <a href={'/'+service.url}>
                                            <div className="item-text">                                    
                                                <h6>{`${service.code_name} in ${service.place}`}</h6>
                                            </div>
                                        </a>
                                    </li>    
                                )
                            })
                        }                   
                        </ul>
                </article>
            </div>)
    }
};