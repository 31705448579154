import React from "react";
import QuoteRequestModalLibrary from "./quote-request-modal-library"

export default class ProfilesCardsDumpster extends React.Component {



    constructor(props) {
        super(props)

        this.profiles = []
        if (this.props.profiles.length > 0) {
            this.profiles = this.props.profiles
        }
        this.profilesById = this.props.cards
        this.cardsPerPage = 6;
        this.defaultCards = 15;
        // Sub01 to Sub09 limit to 12 cards per page
        if(this.props.place.ht?.includes('Sub')){
			this.defaultCards = 12;
		}
        this.page = 0;

        this.code = this.props.code;
        this.place = this.props.place;
        this.onlineBookingPros = this.props.online_booking_pros;

    }

    addQuote = (id, data) => {
        const isBasic = data.includes('data-basic="true"');
        if(!isBasic){
            if(this.code != 'comercial-trash-service'){
                if(this.code === 'junk-removal'){
                    return (
                        <div className="flex-footer">
                            <div class='call-to-action-info'>
                                <span>+</span>
                                <p>Request pricing & availability</p>
                            </div>
                            <QuoteRequestModalLibrary
                                showButton={true}
                                startVisible={false}
                                proid={id}
                                ondeffCode='junkRemovalQuoteRequest'
                            />
                        </div>
                    ) 
                }else{
                    if(this.onlineBookingPros.includes(+id)){
                        return(
                            <div className="flex-footer">
                                <div class='call-to-action-info'>
                                    <span>+</span>
                                    <p>Online pricing</p>
                                </div>
                                <div class='call-to-action-info'>
                                    <span>+</span>
                                    <p>Book online</p>
                                </div>
                                <button class='view-prices' onClick={() => this.handleRedirect(data)}>View Prices</button>
                            </div>
                        )
                    }else{
                        return (
                            <div className="flex-footer">
                                <div class='call-to-action-info'>
                                    <span>+</span>
                                    <p>Request pricing & availability</p>
                                </div>
                                <QuoteRequestModalLibrary
                                    showButton={true}
                                    startVisible={false}
                                    proid={id}
                                    ondeffCode='dumpsterQuoteRequest'
                                />
                            </div>
                        )  
                    }
                }
            }
        }
        return <></> 
    }

    addMobileQuoteTitle = () => {
        switch(this.code) {
            case 'comercial-trash-service':
                return (
                    <div className="item">
                        <span className="mobile-quote-title">Compare commercial waste providers and call to request a quote.</span>
                    </div>
                )
                break;
            case 'junk-removal':
                return (
                    <div className="item">
                        <span className="mobile-quote-title">Compare Junk Removal Quotes</span>
                        <div className="flex-footer" style={{ borderTop: 'none' }}>
                            <QuoteRequestModalLibrary
                                showButton={true}
                                startVisible={false}
                                ondeffCode='junkRemovalQuoteRequest'
                                mobileQuoteLabel='Get Quotes Now'
                            />
                        </div>
                    </div>
                )
                break;
            default: 
                return (
                    <div className="item">
                        <span className="mobile-quote-title">Compare Dumpster Rental Quotes</span>
                        <div className="flex-footer" style={{ borderTop: 'none' }}>
                            <QuoteRequestModalLibrary
                                showButton={true}
                                startVisible={false}
                                ondeffCode='dumpsterQuoteRequest'
                                mobileQuoteLabel='Get Quotes Now'
                            />
                        </div>
                    </div>
                )
                break;
        }
    }

    handleRedirect = (data) => {
        const url = data.split(/data-url="([^"]+)/)
        if(url?.length === 3){
            window.location.href = url[1];
        }
    }

    render() {
        const handleClick = async (e) => {
            e.preventDefault();
            var profilesByIdAux = this.profilesById
            const localCode = this.code === 'dumpster' ? 'dumpster-rental' : this.code === 'comercial-trash-service' ? 'commercial-waste-management' : this.code
            this.page = this.page + 1;
            return await Promise.all(this.profiles.slice(this.defaultCards + ((this.page - 1)*this.cardsPerPage), (this.defaultCards + this.page*this.cardsPerPage)).map((profile, index) => {
                return fetch('/pros/' + localCode + '/' + profile.id + '.html')
                .then(res => {
                    return res.text();
                })
                .then(res => {
                    if (res.startsWith("<div class=\"flex-head\"")) {
                        res = res.replace(/\[place\]/g, this.place.name);
                        profilesByIdAux[profile.id] = res;
                        return profile
                    }
                    else {
                        throw new Error('Invalid card');
                    }
                    
                }).catch((error) => {
                    profile.remove = 1
                    return profile
                });
            })).then(() => {
                this.setState({
                    profilesById: profilesByIdAux
                })
                return this.forceUpdate()
            })            
        }

        return (
            <div className="items-grid prof dumpster">
                <div className="container">
                    <div id="profiles-cards-list" className="row">

                        <div style={{minHeight: 150 + 'px'}} key="mainitem" className="col-12 mobile-only universal-card">
                            {this.addMobileQuoteTitle()}
                        </div>
                        {
                            this.profiles.slice(0, (this.defaultCards + this.page*this.cardsPerPage)).filter(item => item.remove != 1).map((profile, index) => {
                               const pro = profile
                               if(this.profilesById[pro.id]){
                                    return (
                                        <div data-proid={pro.id} key={index} className="card-pro-view">
                                            <div data-id={pro.id} className="item" >
                                                <div dangerouslySetInnerHTML={{__html: this.profilesById[pro.id]}} onClick={() => this.handleRedirect(this.profilesById[pro.id])}>
                                                
                                                </div>
                                                {this.code != 'comercial-trash-service' ? 
                                                this.addQuote(pro.id, this.profilesById[pro.id])
                                                :
                                                <></>
                                                } 
                                            </div>
                                        </div>
                                    )
                                }else{
                                    profile.remove = 1
                                    return <></>
                                }  
                            })
                        }
                    </div>
                    {this.profiles?.length > (this.defaultCards + this.page*this.cardsPerPage) &&
                    <div className="load-more-container">
                        <button onClick={handleClick} className="load-more-btn">Show More</button>
                    </div>
                   }
                </div>
            </div>
        )
    }
}