import React, { lazy, Suspense } from "react"
import LayoutDumpster from "../components/layout-dumpster"

import RelatedServices from '../components/related-services'
import PopularServices from '../components/popular-services'
import PopularArticles from '../components/popular-articles'
import ZipFinderKpps from '../components/zip-finder-kpps'
import KppTips from "../components/kpptips"
import HowToGuide from '../components/how-to-guides-dumpsters'
import ProfilesCardsDumpster from "../components/profiles-cards-dumpsters"
import EmptyProfilesDumpster from "../components/empty-profiles-dumpster"
import BlockHowToGuidesJunkRemoval from "../components/how-to-guides-junk-removal"





const DumpsterKPP = ({ pageContext }) => {
    const cards = pageContext.cards
    const title = pageContext.title
    const profiles = pageContext.profiles.length > 0 ? pageContext.profiles[0] : []
    const env = pageContext.env
    const articles = pageContext.articles
    const code = pageContext.code
    const codes = pageContext.codes.length > 0 ? pageContext.codes : []
    const populars = pageContext.populars.length > 0 ? pageContext.populars : []
    const related = pageContext.related
    const keygroup_name = pageContext.page === 'kpp' ? undefined : pageContext.keyword_group.name
    const bread = pageContext.page === 'kpp' ? undefined : (pageContext.bread ? pageContext.bread.url : '#')
    const tip = pageContext.tips.length > 0 ? pageContext.tips[0] : {}

    const text = code === 'junk-removal' ? 'Get matched to top-rated junk removal services' : 'Find the top dumpster rental companies serving your hometown';
    const empty = profiles.length > 0 ? false : true

    const place =
    {
        name: pageContext.place.name,
        ht: pageContext.place.ht,
        state:
        {
            name: pageContext.place.state.name,
            av: pageContext.place.state.av,
            slug: pageContext.place.state.slug
        }
    }

    

    const seo_desc = pageContext.seo_desc
    const seo =
    {
        url: pageContext.url,
        env: env,
        place: place,
        code: code,
        page: pageContext.page,
        seo_desc: seo_desc,
        schema: pageContext.schemaOrg
    }

    const online_booking_pros = pageContext.enabledBooking

    return (
        <LayoutDumpster seo={seo} env={env} code={code} footerColor="white" >


            <ZipFinderKpps
                id='home-zip-code'
                code={code}
                title={title}
                text={text}
                button='Find Pros'
                codes={codes}
                env={env}
                finderopenmodal={false}
                breadcrumb={true}
                keygroup_name={keygroup_name}
                bread={bread}
                url={pageContext.url}
                place={place}
            />

            <div id="profiles-id-list" className="div d-none" data-htcode={code === 'dumpster' ? 'dumpster-rental' : code === 'comercial-trash-service' ? 'commercial-waste-management' : code}>{pageContext.pros}</div>

            {empty && <EmptyProfilesDumpster code={code} />}

            {!empty && <ProfilesCardsDumpster profiles={profiles} env={env} code={code} place={place} cards={cards} online_booking_pros={online_booking_pros}/>}

                <div className="bg-grey pt-5 pb-5">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-4">
                                {code !== 'junk-removal' ?
                                <HowToGuide />
                                :
                                <BlockHowToGuidesJunkRemoval />
                                }
                            </div>
                            {related.length > 0 && <div className="col-md-4"><RelatedServices env={env} services={related} place={place} code={code} /></div>}
                            {populars.length > 0 && <div className="col-md-4"><PopularServices env={env} services={populars} place={place} code={code} /></div>}
                        </div>
                        <div className='stateArticles'><PopularArticles articles={articles} /></div>
                    </div>
                </div>

                <div className="bg-grey container-fuild">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <KppTips tip={tip} codes={codes} code={code} place={place} />
                            </div>
                        </div>
                    </div>
                </div>

        </LayoutDumpster>
    )
}

export default DumpsterKPP