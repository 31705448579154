import React from "react"

export default class Breadcrumb extends React.Component 
{
	constructor(props) 
    {
		super(props);  
		
		this.code = this.props.code;
		this.codes = this.props.codes;			

		this.slug = this.props.place.state !== undefined ? this.props.place.state.name : this.code_name;
		this.state_av = this.props.place.state !== undefined ? this.props.place.state.av : '';		
		this.calssesplace = this.props.place.name !== undefined ? 'breadcrumb-item' : 'breadcrumb-item hide';					
		this.keygroup_name = this.props.keygroup_name !== undefined ? this.props.keygroup_name : '';
        this.keygroup_name_class = this.keygroup_name !== '' ? 'breadcrumb-item' : 'breadcrumb-item hide';
        this.bread = this.props.bread;

		this.class =  this.props.class !== undefined ? this.props.class : '';
	}

	codeName = () =>
	{
		let name, url, code_path = '';

		this.codes.map(code =>
		{			
			if(code.code === this.code)
			{				
				name = code.name;
				code_path = code.paths.code;

				switch (code.code) 
				{						
					case 'residential-demolition':
						code_path = 'deck-and-fence-removal'
						break;
	
					case 'oil-tank-removal':
						code_path = 'tank-removal';
						break;
					
					case '10-yard-dumpster': case '20-yard-dumpster': case 'construction-dumpster': case 'roll-off-dumpsters':
						name = 'Dumpster Rental';
						code_path = 'dumpster-rental';
						break;
	
					case 'house-demolition':
						code_path = 'house-and-garage-demolition';
						break;

					case 'demolition-contractors':
						code_path = ''
						break;
						
					default: break;
				}

				url = '/'+code_path;						
				return true;
			}

			return true;
		})	
		
		return(
			<li itemScope="itemscope" itemType="http://schema.org/ListItem" itemProp="itemListElement" className="breadcrumb-item">
				<a itemProp="item" href={url}>
					<span itemProp="name">{name}</span>
				</a>
				<meta itemProp="position" content="2" />
			</li>
		)
	}

	place = () =>
	{        
        let classes = '';
        let name = this.props.place.name !== undefined ? this.props.place.name.split(',')[0] : '';
        let url = this.bread !== '' ? this.bread : this.props.url;
		
		if(this.keygroup_name === '') classes +='noarrow';

		classes += this.props.place.name !== undefined ? ' breadcrumb-item' : ' breadcrumb-item hide';

		if(this.keygroup_name === '') return(<li className={classes}><span itemProp="name">{name}</span></li>)

		return(
			<li itemScope="itemscope" itemType="http://schema.org/ListItem" itemProp="itemListElement" className={classes}>
				<a itemProp="item" href={'/'+url} >
					<span itemProp="name">{name}</span>
				</a>
				<meta itemProp="position" content="4" />
			</li>
		)
	}

	stateName = () =>
	{
		let classes = 'breadcrumb-item';

		if(this.props.place.name === undefined) classes +=' noarrow';

		let state_url; 
        
        this.codes.map(code =>
        {    		                   
            if(code.code === this.code)
            {
                switch (this.slug) 
                {
                    case 'district-of-columbia': case 'District of Columbia': 
						state_url = `/district-of-columbia/${code.code}-district-of-columbia-dc`;
						if(code.code === '10-yard-dumpster' || code.code === '20-yard-dumpster' || code.code === 'construction-dumpster' || code.code === 'roll-off-dumpsters'){							
							state_url = `/district-of-columbia/dumpster-district-of-columbia-dc`;
						}
                        break;
                
                    default:

						const slug = this.slug.replace(/ /g,'-')				
					
						switch (code.code) 
                        {
                            case '10-yard-dumpster': case '20-yard-dumpster': 
							case 'construction-dumpster': case 'roll-off-dumpsters':
							case 'dumpster': case 'demolition-contractors':
								state_url = `/${slug}/index.html`;
								break;						
                                
                            default:
								const fixedcode = code.code === 'comercial-trash-service' ? 'commercial-waste-management' : code.code
                                state_url = `/${slug}/${fixedcode}/index.html`;
                            break;							
                        }

                        break;
                }
            }

			return true;
        })
		
		if(this.props.place.name === undefined) 
			return(
				<li className={classes}><span>{this.state_av}</span></li>
			);

		return( 
			<li itemScope="itemscope" itemType="http://schema.org/ListItem" itemProp="itemListElement" className={classes}>
				<a itemProp="item" href={state_url} >
					<span itemProp="name">{this.state_av}</span>
				</a>
				<meta itemProp="position" content="3" />
			</li>
		)
	}

    keygroup = () =>
    {
        if(this.keygroup_name_class === 'breadcrumb-item hide') return <></>
		
		return(
			<li className={this.keygroup_name_class}><span>{this.keygroup_name}</span></li>
		)
    }

	render()
	{
		return	(
			
			<nav aria-label="breadcrumb">
				<ol itemScope="itemscope" itemType='http://schema.org/BreadcrumbList' className={`breadcrumb ${this.class}`}>
					<li itemScope="itemscope" itemType="http://schema.org/ListItem" itemProp="itemListElement" className="breadcrumb-item">
						<a itemProp="item" href="/">
							<span itemProp="name">Hometown</span>														
						</a>
						<meta itemProp="position" content="1" />
					</li>
					{this.codeName()}
					{this.stateName()}
					{this.place()}
                    {this.keygroup()}					
				</ol>
			</nav>
		)
	}
	
}