import React from "react";

export default class BlockHowToGuidesJunkRemoval extends React.Component  
{
    
    render()
    {
        return (
            <div className="block how-to-guides">
                <h2>How-To Guides</h2>
                <div className="provider-card card">    
                    <ul className="items-list">

                        <li className="item">
                            <a href={'/blog/junk-removal-101'}>
                                <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-118px 0px' }}></span>
                                <div className="item-text">
                                    <h5>GETTING STARTED</h5>
                                    <h6>Junk removal 101 begins</h6>
                                </div>
                            </a>
                        </li>
                        <li className="item">
                            <a href={'/blog/how-much-does-it-cost-to-haul-away-junk'}>
                                <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-300px 0px' }}></span>
                                <div className="item-text">
                                    <h5>COST</h5>
                                    <h6>Learn how to get the best price</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/on-site-junk-removal'}>
                                <span className="icon-jr" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-1225px 0px' }}></span>
                                <div className="item-text">
                                    <h5>ESTIMATES</h5>
                                    <h6>Know what to expect from on-site quotes</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/how-cleanout-services-work'}>
                                <span className="icon-jr" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-1115px 0px' }}></span>
                                <div className="item-text">
                                    <h5>CLEANOUTS</h5>
                                    <h6>Understand how cleanout services work</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/rent-a-dumpster-or-hire-a-junk-removal-company'}>
                                <span className="icon-jr" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-1058px 0px' }}></span>
                                <div className="item-text">
                                    <h5>OPTIONS</h5>
                                    <h6>Hire junk removal or rent a dumpster</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/diy-vs-professional-junk-removal-which-option-is-right-for-you'}>
                                <span className="icon-jr" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-1170px 0px' }}></span>
                                <div className="item-text">
                                    <h5>DIY</h5>
                                    <h6>Compare DIY junk removal to hiring the pros</h6>
                                </div>
                            </a>
                        </li> 
                    </ul>                    
                </div>
            </div>
        )
    }
};