import React from "react";

export default class RelatedServices extends React.Component  
{
    constructor(props)    
    {
        super(props);        
        
        const code = this.props.code;
        const place = this.props.place !== undefined ? this.props.place.name : '';        
        const place_name = place.replace(/ /g,'-').replace(/,/g,'').replace(/---/g,'-').toLowerCase(); 
        const place_type = this.props.place !== undefined ? this.props.place.ht : '';
        
        this.place = place;
        
        this.services = [];

        this.props.services.map(service =>
        {    
            const service_data =
            {
                name: service.title,
                url: service.url
            }

            this.services.push(service_data);

            return true;
        })   
        
        const expresion_hub = /^Hub/g;
        const expresion_sub = /^Sub/g;
        
        if(place_type.match(expresion_hub) || place_type.match(expresion_sub))
        {
            this.services.push({
                'name': '10 Yard Dumpsters for Rent',
                'url': 'h/'+place_name+'/10-yard-dumpster',
                'code': 'static'
            });
    
            this.services.push({
                'name': 'Rent a 20 Yard Dumpster',
                'url': 'h/'+place_name+'/20-yard-dumpster',
                'code': 'static'
            });
    
            this.services.push({
                'name': 'Construction Dumpster',
                'url': 'h/'+place_name+'/construction-dumpster',
                'code': 'static'
            });
    
            this.services.push({
                'name': 'Roll Off - Dumpsters for Rent',
                'url': 'h/'+place_name+'/roll-off-dumpsters',
                'code': 'static'
            });
        }                

        this.ulclass = code !== undefined ? code : '';
    }
    
    render()
    {
        return (
            <div className="block related-services">
                <h2 className="text-center">Related Services in {this.place}</h2>
                <article className="provider-card card">
                    <ul className={"items-list "+this.ulclass}>
                        {
                            this.services.map((service, index) =>
                            {                                
                                return(
                                    <li key={index} className={"item"}>
                                        <a href={'/'+service.url}>
                                            <div className="item-text">                                    
                                                <h6>{service.name}</h6>
                                            </div>
                                        </a>
                                    </li>    
                                )
                            })
                        }                   
                        </ul>
                </article>
            </div>)
    }
};