import React from "react";

export default class BlockHowToGuidesDumpsters extends React.Component  
{
    
    render()
    {
        return (
            <div className="block how-to-guides">
                <h2>How-To Guides</h2>
                <div className="provider-card card">    
                    <ul className="items-list">

                        <li className="item">
                            <a href={'/blog/dumpster-rental-101'}>
                                <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-118px 0px' }}></span>
                                <div className="item-text">
                                    <h5>GETTING STARTED</h5>
                                    <h6>Dumpster rental 101 begins</h6>
                                </div>
                            </a>
                        </li>
                        <li className="item">
                            <a href={'/blog/the-ultimate-guide-to-dumpster-sizes'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-570px 0px' }}></span>
                                <div className="item-text">
                                    <h5>SIZES</h5>
                                    <h6>Ask for the right dumpster size</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/roll-off-dumpster-prices'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-300px 0px' }}></span>
                                <div className="item-text">
                                    <h5>PRICES</h5>
                                    <h6>Lower your cost to rent</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/what-can-i-put-in-a-dumpster'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-30px 0px' }}></span>
                                <div className="item-text">
                                    <h5>Debris</h5>
                                    <h6>What can and can't go in a dumpster</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/dumpster-rental-terms-and-conditions-a-closer-look-at-the-fine-print'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-390px 0px' }}></span>
                                <div className="item-text">
                                    <h5>RENTAL TERMS</h5>
                                    <h6>Understand what you're agreeing to</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/hiring-the-right-dumpster-service-everything-you-need-to-know'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-480px 0px' }}></span>
                                <div className="item-text">
                                    <h5>SERVICE</h5>
                                    <h6>Comparing dumpster services</h6>
                                </div>
                            </a>
                        </li> 
                        <li className="item">
                            <a href={'/blog/rent-a-dumpster-or-hire-a-junk-removal-company'}>
                            <span className="icon" style={{ backgroundImage: `url('/images/icons/kpp-icons.svg')`, backgroundPosition: '-208.5px 0px' }}></span>
                                <div className="item-text">
                                    <h5>OPTIONS</h5>
                                    <h6>Rent a dumpster or hire junk removal</h6>
                                </div>
                            </a>
                        </li> 
                    </ul>                    
                </div>
            </div>
        )
    }
};