import React from "react"

export default function HeaderMenuDumpster() 
{    
    return(
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item dropdown" key="hm_item_1" >
                    <a id="hm_mainopt" className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" data-target="dropdown-menu" aria-expanded="false" onClick={() => {document.getElementById('dropdownHeader').classList.toggle('show')}}>Find Pros</a>
                    <ul id="dropdownHeader" key="hms_item_1" className="dropdown-menu dropdown-menu-right" aria-labelledby="hm_mainopt">
                        <li key="hms_item_11"><a className="dropdown-item" href="/dumpster-rental">Dumpster Rental</a></li>
                        <li key="hms_item_12"><a className="dropdown-item" href="/junk-removal">Junk Removal</a></li>
                        <li key="hms_item_13"><a className="dropdown-item" href="/commercial-waste-management">Commercial Waste</a></li>
                    </ul>
                </li>
                <li className="nav-item" key="hm_item_2" >
                    <a className="nav-link" href="/blog" role="button" data-toggle="" data-target="dropdown-menu" aria-expanded="false">Tips & Advice</a>
                </li>
                <li className="nav-item" key="hm_item_3" >
                    <a className="nav-link" href="/pro/guide/dumpster-junk-leads" role="button" data-toggle="" data-target="dropdown-menu" aria-expanded="false">Join as a Pro</a>
                </li>
            </ul>
        </div>
    )
}